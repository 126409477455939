<template>
  <div>
    <label v-if="showLabel && (hasPermissionForAction('delete') || hasPermissionForAction('add'))">{{ $tf(this.object) }}</label>
    <div
      name="objects"
      class="objects-line cols flex-multi"
      :class="classes"
      @drop.stop.prevent="dropHandler"
      @dragover.stop.prevent="dragOverHandler"
      @dragleave.stop="dragLeaveHandler"
    >
      <object-thumbnail
        :object="object"
        :target="target"
        :editable="editable && hasPermissionForAction('delete')"
        :selectable="selectable"
        :selected="item && hasSelectedFace(item)"
        :showId="showId"
        :externalItem="item"
        v-for="(item, idx) in items"
        :key="model + idx"
        @preview="showObject(item)"
        @delete="deleteObject(item)"
        @select="selectFace(item)"
      />

      <template v-if="editable">
        <object-thumbnail
          :target="target"
          :editable="editable"
          :local="true"
          :url="item.thumbnail || item.source_photo"
          v-for="(item, idx) in fakeItems"
          :orientation="item.detect && item.detect.orientation"
          :key="model + idx"
          @preview="showObject(item)"
          @delete="deleteFakeItem(idx)"
        />
      </template>

      <el-button
        name="add-btn"
        ref="buttonAdd"
        size="large"
        class="face--button-add"
        :loading="imageLoading || detecting"
        v-if="editable && hasPermissionForAction('add')"
      >
        <div v-if="!(imageLoading || detecting)">
          <div class="default">
            <i class="el-icon el-icon-plus"></i>
          </div>

          <div class="hover">
            <i class="fa fa-image" @click.stop="$refs.fileInput.click()"></i>
            <i class="fa fa-camera" @click.stop="openScreenshotDialog"></i>
          </div>
        </div>
      </el-button>
      <input name="file" type="file" ref="fileInput" @change="addFile" class="hidden" />

      <el-dialog
        name="screenshot-dialog"
        :title="$tfo('screenshot')"
        v-if="screenshotMaker.enabled"
        :visible.sync="screenshotMaker.enabled"
        class="face-dialog--container"
      >
        <screenshot @screenshot="onScreenshot" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ObjectThumbnail from './thumbnail.vue';
import DossierListsInline from '../dossier-lists/inline.vue';
import Screenshot from '../screenshot/index';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import _ from '@/apps/common/lodash';

export default {
  components: {
    ObjectThumbnail,
    DossierListsInline,
    Screenshot
  },
  name: 'objects-inline',
  props: {
    object: {
      type: String,
      default: 'faces'
    },
    target: {
      type: String,
      default: 'dossier'
    },
    id: {
      type: [Number, String]
    },
    showLabel: {
      type: Boolean,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    limit: Number,
    editable: Boolean,
    selectable: Boolean,
    showId: Boolean,
    selectedIds: Array,
    loadDebounce: Boolean
  },
  data: function () {
    return {
      dragover: false,
      selected: [],
      detecting: false,
      imageLoading: false,
      items: [],
      fakeItems: [],
      screenshotMaker: {
        enabled: false
      }
    };
  },
  computed: {
    classes() {
      return {
        'objects-line--dragover': this.dragover
      };
    },
    activeItems() {
      return this.items.length ? this.items : this.fakeItems;
    },
    isBrowserOrientationOld() {
      return this.$store.getters.isBrowserOrientationOld;
    },
    isDossier() {
      return this.target === 'dossier';
    },
    modelPath() {
      return this.isDossier ? `objects/${this.object}` : `${this.target}-face`;
    },
    model() {
      return this.isDossier ? `${this.object}_objects` : `${this.target}_face`;
    }
  },
  watch: {
    id: function (v, p) {
      this.selected = [];
      this.items = [];
      this.$emit('selectedChange', this.selected);
      v && (this.fakeItems.length ? this.migrateItems() : this.loadItems())?.then(() => this.afterCreate());
    },
    editable: function (v, p) {
      this.applyEditable(true);
    }
  },
  created() {
    if (this.loadDebounce) this.loadItems = _.debounce(this.loadItems, 750);
  },
  mounted() {
    if (this.id) {
      this.loadItems();
    }
    this.selected = this.selectedIds || [];
    this.applyEditable();
    let eventId = this.$route.query?.eventId;
    if (eventId) {
      this.$store.dispatch(this.$store.state[`${this.object}_events`].Action.Get, { id: decodeURIComponent(eventId) }).then((v) => {
        this.addFromEvent(v);
      });
    }
  },
  beforeDestroy() {
    if (this.editable) {
      this.$store.state.app.fileHandler = null;
    }
  },
  methods: {
    onScreenshot(blob) {
      this.closeScreenshotDialog();
      let filename = Math.random().toString().slice(2, 8) + '.png',
        file = new File([blob], filename, { type: blob.type, lastModified: Date.now() });
      this.addFileInternal(file);
    },
    openScreenshotDialog() {
      this.screenshotMaker.enabled = true;
    },
    closeScreenshotDialog() {
      this.screenshotMaker.enabled = false;
    },
    applyEditable() {
      this.$store.state.app.fileHandler = null;
    },
    afterCreate() {
      this.$emit('created');
    },
    createFromEvent(v) {
      let { event } = v,
        formData = new FormData();
      const computedEventValue = `${ObjectsTypeSingleForm[this.object]}event:${event.id}`;

      formData.append('create_from', computedEventValue);
      formData.append('dossier', this.id);
      this.imageLoading = true;
      return this.$store
        .dispatch('requestApi', { model: this.modelPath, data: formData, method: 'post', timeout: 6e4 })
        .then(() => {
          this.loadItems();
          this.imageLoading = false;
          return Promise.resolve(true);
        })
        .catch((e) => {
          console.error(e);
          this.imageLoading = false;
          this.$notify({
            title: this.$tf(`upload_${this.object}_failed`),
            duration: 0,
            message: this.$createElement('message-box', { props: { e: e } })
          });
          return Promise.resolve(true);
        });
    },
    migrateItems() {
      return Promise.all(
        this.fakeItems.map((v) =>
          (v.event ? this.createFromEvent(v) : this.loadFile(v.file, v.detect, this.id)).then(() => {
            this.fakeItems = this.fakeItems.filter((i) => i.file !== v.file);
          })
        )
      ).then(() => console.log('[faces:migrate] Finished'));
    },
    loadItems() {
      this.selected = [];
      let respPromise;
      if (this.id && this.limit === 1) {
        if (this.isDossier) {
          respPromise = this.$store.dispatch(`getBatchObject${this.$filters.firstCase(this.object)}`, {
            dossier: this.id
          });
        }
      } else {
        if (this.id) {
          respPromise = this.$store.dispatch(this.$store.state[this.model].Action.Get, {
            filter: {
              [this.target]: this.id,
              limit: this.limit || 1e3
            }
          });
        }
      }
      return (
        respPromise &&
        respPromise
          .then((v) => {
            this.items = v.results;
            this.$emit('loaded', this.items, this?.index);
            if (this.selectable && !this.selected.length && this.items.length) {
              this.selected = [this.items[0].id];
              this.$emit('selectedChange', this.selected);
            }
          })
          .catch((e) => {
            this.$emit('loadError', e);
            console.warn(`Unable to load items\n ${e}`);
          })
      );
    },
    showObject(item) {
      const bbox = {
        w: item.source_coords_right - item.source_coords_left,
        h: item.source_coords_bottom - item.source_coords_top,
        x: item.source_coords_left,
        y: item.source_coords_top
      };
      this.$store.dispatch('showImage', { src: item.source_photo, [`${this.object}_bbox`]: [bbox] });
    },
    deleteObject(item) {
      this.$confirm(this.$tf('photo_delete_confirm'), this.$tf('common.warning'), {
        confirmButtonText: this.$tf('common.yes'),
        cancelButtonText: this.$tf('common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch(this.$store.state[this.model].Action.Delete, item).then((v) => {
            this.loadItems();
          });
        })
        .catch(() => {});
    },
    deleteFakeItem(id) {
      this.fakeItems.splice(id, 1);
    },
    addRemote(url) {
      fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          let filename = url.substring(url.lastIndexOf('/') + 1) || new Date().getTime().toString(32) + '.jpg',
            file = new File([blob], filename, { type: blob.type, lastModified: Date.now() });
          this.addFileInternal(file);
        });
    },
    addFile(e) {
      let file = e.target && e.target.files[0];
      this.$refs.fileInput.value = null;
      this.addFileInternal(file);
    },
    async addFileInternal(file) {
      try {
        this.detecting = true;
        const detectResult = await this.$store.dispatch('detectObjects', { file, objects: this.object });
        this.detecting = false;

        if (this.isDossier) {
          const duplicates = await this.$store.dispatch('searchSameObjects', {
            id: detectResult.id,
            objects: this.object,
            excludedDossierId: this.id
          });

          if (duplicates?.length) {
            try {
              const { type, dossierId } = await this.$store.dispatch('choose_deduplication', {
                detect: detectResult,
                duplicate: duplicates[0]
              });
              if (dossierId) return this.loadFileAndRedirect(file, detectResult.id, dossierId);
            } catch (e) {
              console.warn('[deduplicate]:error ', e);
              return;
            }
          }
        }

        if (!this.id) {
          this.fakeItems.push({
            file,
            detect: detectResult.id,
            thumbnail: detectResult.thumbnail,
            source_photo: URL.createObjectURL(file)
          });
          return true;
        } else {
          return this.loadFile(file, detectResult.id, this.id);
        }
      } catch (e) {
        this.$notify({
          title: this.$tf(`upload_${this.object}_failed`),
          duration: 0,
          message: this.$createElement('message-box', { props: { e } })
        });
      } finally {
        this.detecting = false;
      }
    },

    async loadFileAndRedirect(file, detectId, parentId) {
      await this.loadFile(file, detectId, parentId);
      this.$router.push(`/dossiers/${parentId}`);
    },

    loadFile(file, detectId, parentId) {
      const formData = new FormData();

      formData.append('source_photo', file);
      formData.append(this.target, parentId);
      formData.append('detect_id', detectId);

      this.imageLoading = true;
      return this.$store
        .dispatch('requestApi', { model: this.modelPath, data: formData, method: 'post', timeout: 6e4 })
        .then((v) => {
          this.loadItems();
          this.imageLoading = false;
          return Promise.resolve(true);
        })
        .catch((e) => {
          console.error(e);
          this.imageLoading = false;
          this.$notify({
            title: this.$tf(['common.upload', 'common.face,,1', 'common.failed,,1']) + '. ',
            duration: 0,
            message: this.$createElement('message-box', { props: { e: e } })
          });
          return Promise.resolve(true);
        });
    },
    hasSelectedFace(item) {
      return this.selected.indexOf(item.id) > -1;
    },
    selectFace(item) {
      const index = this.selected.indexOf(item.id);
      index === -1 ? this.selected.push(item.id) : this.selected.splice(index, 1);
      this.$emit('selectedChange', this.selected);
    },
    selectAll() {
      this.selected = this.items.map((v) => v.id);
      this.$emit('selectedChange', this.selected);
    },
    unselectAll() {
      this.selected = [];
      this.$emit('selectedChange', this.selected);
    },
    addFromEvent(event) {
      const url = event.fullframe;
      fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          let filename = url.substring(url.lastIndexOf('/') + 1) || new Date().getTime().toString(32) + '.jpg',
            file = new File([blob], filename, { type: blob.type, lastModified: Date.now() });
          const detectObj = {
            file: file,
            detect: { faces: [{ selected: true, orientation: 1, box: [0, 0, 200, 200] }] },
            source_photo: URL.createObjectURL(file),
            thumbnail: event.thumbnail,
            event
          };
          this.fakeItems.push(detectObj);
        });
    },
    dropHandler(e) {
      this.dragover = false;
      if (!this.editable) return;

      const transferItems = e.dataTransfer?.items;
      if (transferItems?.length) {
        const files = Array.prototype.slice
          .call(transferItems)
          .map((v) => v.kind === 'file' && v.getAsFile())
          .filter((v) => !!v);
        files.forEach((v) => this.addFileInternal(v));
      }
      return false;
    },
    dragOverHandler(e) {
      if (this.editable) this.dragover = true;
      return this.dragover;
    },
    dragLeaveHandler(e) {
      this.dragover = false;
    },
    getSingleObject() {
      return ObjectsTypeSingleForm[this.object];
    },
    hasPermissionForAction(action) {
      return !this.$hasNoPermission('ffsecurity.' + action + '_' + this.getSingleObject() + 'object');
    }
  }
};
</script>

<style lang="stylus">

.objects-line {
  width: 100%;
  margin-bottom: 1rem;
  border: solid rgba(255, 255, 255, 0) 2px;
  transition: border 0.2s;

  &--dragover {
    border-radius: 0.5rem;
    border: solid rgba(255, 255, 255, 0.1) 2px;
  }

}

.face--button-add {
  border-radius: 0.25rem !important;
  display: inline-flex;
  margin: 0 1rem 1rem 0;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;

  .fa {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.8);
  }

  .fa-camera {
    font-size: 1.4rem;
  }

  .fa-image:hover, .fa-camera:hover {
    color: rgba(255, 255, 255, 1);
  }

  .el-icon {
    font-size: 3rem;
  }

  .default {
    display: block;
  }

  .hover {
    display: none;
  }

  &:hover {
    .default {
      display: none;
    }

    .hover {
      display: block;
    }
  }

  span {
    display: flex;
    justify-content: center;
  }

  i {
    margin: 0 1rem;
    font-size: 2.2rem;
  }
}

.duplicate-info {
  margin-bottom: 2rem;
}

.hidden {
  display: none;
}

.dedup-dossier-name {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.name-and-lists {
  max-width: 300px;
}
</style>
